import React from "react"
import { Link } from "gatsby"
import { Row, Col } from "reactstrap"
import Image from "../image"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faFacebookSquare,
  faLinkedin,
} from "@fortawesome/free-brands-svg-icons"
import { faArrowAltCircleRight } from "@fortawesome/free-solid-svg-icons"

const Footer = () => {
  return (
    <React.Fragment>
      <div className="bg-dark p-4">
        <Row className="m-0">
          <Col xs={12} md={3}>
            <Link to="/">
              <Image src="footer-logo.png" />
            </Link>
            <div className="about-us-footer mt-3">
              <h5 className="text-warning">About Us</h5>
              <p className="text-light">
                Your Repair Experts has stayed true to its original mission:
                bring educated buyers and sellers together at the time they are
                ready to do business
              </p>
            </div>
          </Col>
          <Col xs={12} md={1} className="footer-line-section" />
          <Col xs={12} md={4} className="p-3 p-md-5">
            <div className="footer-links">
              <h5 className="text-warning">Popular Services</h5>
              <ul className="text-light">
                <li>
                  <FontAwesomeIcon icon={faArrowAltCircleRight} />{" "}
                  <Link to="/window-installation-repair-services">
                    Repairing Services
                  </Link>
                </li>
                <li>
                  <FontAwesomeIcon icon={faArrowAltCircleRight} />{" "}
                  <Link to="/cleaning-services">Cleaning Services</Link>
                </li>
                <li>
                  <FontAwesomeIcon icon={faArrowAltCircleRight} />{" "}
                  <Link to="/plumbing-services">Plumbing Services</Link>
                </li>
                <li>
                  <FontAwesomeIcon icon={faArrowAltCircleRight} />{" "}
                  <Link to="/home-builders">Home Builders Services</Link>
                </li>
                <li>
                  <FontAwesomeIcon icon={faArrowAltCircleRight} />{" "}
                  <Link to="/painting-services">Painting Services</Link>
                </li>
                <li>
                  <FontAwesomeIcon icon={faArrowAltCircleRight} />
                  <Link to="/carpentry-services">Carpenter Services</Link>
                </li>
              </ul>
            </div>
          </Col>
          <Col xs={12} md={4} className="p-3 p-md-5">
            <div className="footer-links">
              <h5 className="text-warning">Helpful Links</h5>
              <ul className="text-light">
                <li>
                  <FontAwesomeIcon icon={faArrowAltCircleRight} />
                  <Link to="/services">Services</Link>
                </li>
                <li>
                  <FontAwesomeIcon icon={faArrowAltCircleRight} />
                  <Link to="/about-us">About Us</Link>
                </li>
                <li>
                  <FontAwesomeIcon icon={faArrowAltCircleRight} />
                  <Link to="/privacy-policy">Privacy Policy</Link>
                </li>
              </ul>
            </div>
          </Col>
        </Row>
      </div>
      <div className="py-3 bg-primary text-center px-3">
        <span className="text-light">
          Copyright ©{" "}
          <Link to="/" className="text-light">
            YourRepairExperts
          </Link>{" "}
          All rights reserved | Designed by&nbsp;
          <a href="https://ciphersavvy.com" className="text-light">
            Cipher Savvy
          </a>
        </span>
        <br className="d-md-none" />
        <a
          href="https://www.facebook.com/Your-Repair-Experts-103661011459018"
          target="_blank"
        >
          <FontAwesomeIcon
            icon={faFacebookSquare}
            style={{ fontSize: 28 }}
            className="text-light float-md-right mx-3 mt-3 mt-md-0"
          />
        </a>
        <a
          href="https://www.linkedin.com/company/your-repair-experts"
          target="_blank"
        >
          <FontAwesomeIcon
            icon={faLinkedin}
            style={{ fontSize: 28 }}
            className="text-light float-md-right mt-3 mt-md-0"
          />
        </a>
      </div>
    </React.Fragment>
  )
}

export default Footer
