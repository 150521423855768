import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Header from "./header"
import Footer from "./footer"
import { Helmet } from "react-helmet"

export default ({ children }) => {
  return (
    <>
      <Helmet>
        <script>
          {`(function(w,d,t,r,u)
    {
        var f,n,i;
        w[u]=w[u]||[],f=function()
        {
            var o={ti:"343072311", enableAutoSpaTracking: true};
            o.q=w[u],w[u]=new UET(o),w[u].push("pageLoad")
        },
        n=d.createElement(t),n.src=r,n.async=1,n.onload=n.onreadystatechange=function()
        {
            var s=this.readyState;
            s&&s!=="loaded"&&s!=="complete"||(f(),n.onload=n.onreadystatechange=null)
        },
        i=d.getElementsByTagName(t)[0],i.parentNode.insertBefore(n,i)
    })
    (window,document,"script","//bat.bing.com/bat.js","uetq");`}
        </script>
      </Helmet>
      <StaticQuery
        query={graphql`
          query {
            allMarkdownRemark(
              filter: {
                fileAbsolutePath: { glob: "**/src/markdown-pages/*.md" }
              }
              sort: { order: ASC, fields: [frontmatter___path] }
            ) {
              edges {
                node {
                  id
                  frontmatter {
                    categoryName
                    categoryID
                    path
                  }
                }
              }
            }
          }
        `}
        render={data => (
          <div>
            <Header siteTitle={"data.site.siteMetadata.title"} data={data} />
            <div>
              <main>{children}</main>
            </div>
            <Footer />
          </div>
        )}
      />
    </>
  )
}
